@font-face {
  font-family: roboto-bold-webfont;
  src: url(../fonts/roboto-bold-webfont.eot);
  src: url(../fonts/roboto-bold-webfont.eot?#iefix) format('embedded-opentype'), 
url(../fonts/roboto-bold-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins-Regular.eot);
  src: url(../fonts/Poppins-Regular.eot?#iefix) format('embedded-opentype'), 
url(../fonts/Poppins-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/Poppins-Medium.eot);
  src: url(../fonts/Poppins-Medium.eot?#iefix) format('embedded-opentype'), 
url(../fonts/Poppins-Medium.woff) format('woff');
  font-weight: 400;
  font-style: normal
}
@font-face 
{
font-family: 'lato-regular';
src: 
     url(../fonts/lato-regular.woff) format('woff');
font-weight: normal;
font-style: normal;
}
@font-face 
{
font-family: 'lato-bold';
src: 
     url(../fonts/lato-bold.woff) format('woff');
font-weight: normal;
font-style: normal;
}
*{margin:0;padding:0;}
*, *:before, *:after{box-sizing:border-box;}
ul{list-style:none;}
a, button, input, select{outline:none;border:none;
-webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
 -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
      transition: all 0.20s linear;}
a{text-decoration:none;outline:0;border:none;cursor:pointer;}
a:active, a:hover{outline:none;border:none;}
::placeholder {color:#999999;font-weight:normal;}
:-ms-input-placeholder {color:#999999;font-weight:normal;}
::-ms-input-placeholder {color:#999999;font-weight:normal;}
li {list-style: none;}
body {font-family:Poppins-Regular;font-size:14px;color:#000000;letter-spacing:1px;overflow-x:hidden;}
body, html{height:100%;background:#f5f6f8!important; font-family: Poppins-Regular;}
.mainContainer{width:100%;float:left;height:100%;}
.mainContainer1{width:100%;float:left;height:100%;position: fixed;}


.headerinner{width:100%;float:left;border-bottom: 1px solid #e9ecef;padding:5px 0;}
.headerinner a{width:auto;float:left;}
.headerinner a img{width:100%;float:left;}

.conrgt{width:80%;float:left;position:absolute;right:0;}

.menu{width:100%;float:left;background:#fff;padding:15px 10px;box-shadow: 1px 0 5px rgb(0 0 0 / 8%)}
.menu a{width:auto;float:left;}
.menu ul{width:auto;float:left;}
.menu ul li{width:auto;float:left;padding:0 10px;}
.menu ul li a{width:100%;float:left;color:#5f5f5f;font-size:16px;font-family: Poppins-Medium;}
.menu ul li a .fa{float:right;margin:3px 0 0 5px;}
.menu > ul > li > a{color:#5f5f5f;}
.menu ul li.shops{width: auto;float:left; padding: 5px 15px;color: #000;font-size: 14px;background: #fff001;border-radius:50px;border: 1px solid #fff001;margin-top: 10px;}
.menu ul li a.menuActive{color:#108f00;font-family: Poppins-Medium;}
.menu ul li a:hover{color:#108f00 ;}


.dropItems{width:100%;float:left;padding:15px 0;}
.dropItemsLft{width:100%;float:left;}
.dropItemsLft h2{width:100%;float:left;text-align:center;font-size:14px;padding:10px;border-bottom:1px solid #ccc}
.dropItemsLft ul{width:100%;float:left;padding-left:38%;}
.dropItemsLft ul li{width:100%;float:left;
  padding: 5px 0;}
.dropItemsLft ul li a{width:100%;float:left;padding:0;}
.dropItemsLft ul li a:hover{color:#A2342B}
.dropItemsLft ul li a.Active{color:#A2342B;}

.dropdown-content {width:300px;position: absolute;opacity:0;visibility:hidden;background-color: #fff;box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 0 6px rgba(0,0,0,0.23);  z-index:999;-webkit-font-smoothing: antialiased;
-webkit-transition: all .25s ease;
-moz-transition: all .25s ease;
-ms-transition: all .25s ease;
-o-transition: all .25s ease;    
transition: all .25s ease;top:55px;}
.menu ul li:hover .dropdown-content{opacity:1;visibility:visible;}
.mobilemenu {display:block;}

/*accordian*/
/*Category*/
.cateFilter{width:100%;float:left;overflow-y:auto;max-height:580px;}
.cateFilter li{width:100%;float:left;}
.cateFilter ul li:hover{background:none;}
.cateFilter li:hover{background:#f3f8fb;}
.cateFilter ul li span{float:left;margin-right: 2px;}
.cateFilter ul li span.fa{font-size:12px;}
.cateFilter li a{width:100%;float:left;padding:12px 5px;text-transform:uppercase;font-size:13px;text-decoration:none;font-family:Poppins-Medium;}
.cateFilter li a:hover{color:#0A7CEE!important;}
.cateFilter  > li > a{color:#5f5f5f;}
.cateFilter ul{display:none;width:100%;float:left;background:#fff;padding-left:10px;}
.cateFilter ul li a{color:#7B7B7B;padding:12px 0 0;font-size:12px;}
.cateFilter ul li a:hover{color:#0A7CEE!important;}
.cateFilter span{float:right;}
/* .cateFilter li a.activeCate{color:#ef2365;} */
/*accordian*/

.cateFilterCont{width:20%;padding:0 10px;background: #fff;box-shadow: 1px 0 5px rgb(0 0 0 / 8%);transition: .2s ease-in;z-index:9;display: inline-block;height:100%;position:fixed;}
.cateFilterCont2{width:20%;padding:0 10px;background:#fff;box-shadow: 1px 0 15px rgb(0 0 0 / 8%);
  transition: .2s ease-in;z-index:9;display: inline-block;position:absolute;}

.headerUser{width:auto;float:right;}
.dropdown{width:auto;float:left;padding:0 10px;}
.dropbtn {border: none;cursor: pointer;}
.dropbtn .fa{color: #5f5f5f;font-size:20px;}
.dropbtn .fa:hover{color:#0A7CEE;}
.dropdown {position: relative;display: inline-block;}
.dropdown-content2 {display: none;position: absolute;background-color: #fff;min-width: 350px;overflow: auto;
box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
z-index: 1;top:39px;right:0;}
.dropdown-content2 a {width:100%;
color:#000;padding: 12px 16px;text-decoration: none;display: block;border-bottom: 1px solid #e9ecef;align-items: center;}
.show {display: block;}
.ContainerChart{width:100%;float:left;padding:10px;height: 600px;overflow-y: auto;}
.dropdown-content2 ul{width:100%;float:left;}
.dropdown-content2 ul li{width:100%;float:left;}
.dropdown-content2 ul li {width:100%;float:left;border-bottom: 1px solid #e9ecef;padding: 9px 15px}
.dropdown-content2 ul li:hover{width:100%;float:left;background:#d3d9f8;}
.dropdown-content2 ul li.drbname{width:100%;float:left;}
.drbnameimg{width:50px;float:left;margin-right:20px;}
.drbnameimg img{width:100%;float:left;}
.drbnameimgName{width:68%;float:left;}
.drbnameimgName h3{width:100%;float:left;color: #212529}
.drbnameimgName span{width:100%;float:left;margin: 1px 0;color: #8898aa;}
.drbnameimgName small{width:100%;float:left;margin: 1px 0;color: #8898aa;}
.drobHead{width:100%;float:left;padding:10px;background-color: #5e72e4!important;}
/* .drobHead h3,span{color:#fff;} */
/* .drobHead2 h3,span{color:#fff;} */
.drobHead2{width:100%;float:left;padding:10px;background-color: #f62d51!important;}
.drobHead3{width:100%;float:left;padding:10px;background-color: #f62d51!important;}
.drobHead3 h3{color:#fff;font-weight: bold;margin-bottom: 5px;}
.drobHead3 span{color:#fff;word-break: break-all;font-size:12px;}

.drobHead3 div{width:50px;float:left;margin-right:15px;}
.drobHead3 div img{width:100%;float:left;border-radius:50%;}
.prflBtn{width:100%;float:left;padding:9px 15px;}

.prflBtn button{width:100px;float:left;color: #fff;
  background-color: #0A7CEE;padding:5px;
border-color: #0A7CEE;border-radius:30px;}
.prflBtn button:hover{opacity:0.5;}
.selRslt{width:100%;float:left;padding:20px 2px;background:#fff;}

.selRslt ul{width:80%;margin:0 auto;display:table; padding: 5px;}
.selRslt ul li{width:100%;float:left;margin-bottom:5px;}
.selRsltCont{width:auto;float:left;margin:0 5px;}

.inputLabel{width:24%;float:left;}
.inputLabel label{width:100%;float:left;}
.inputDate{width:25%;float:left;margin:0 4px}
/* .inputDate .startDate{width:100%;float:left;outline:0;color:#000;border:1px solid #ccc;background: url(../img/icons/calender.png) 98% 5px;background-repeat: no-repeat;padding:5px;} */
.inputDate select{width:100%;float:left;float:left;border:1px solid #ccc;padding:4px;}
.inputDate input{width:100%;float:left;float:left;border:1px solid #ccc;padding:5px;}
.BtnCont2{width:auto;float:left;}
.BtnCont2 button{width:100px;padding:3px 10px;background:#ef2365;color:#fff;}
/* .BtnCont2 button:hover{border:1px solid #24a46d;background:none;color:#24a46d;} */

.calendar-header {
background:  linear-gradient(-135deg,#313e9e,#0077ff) !important;
}
.calendar-content .today {
background: linear-gradient(-135deg,#775b05,#e4b300) !important;
}
.calendar-content .day.selected::after{
border-top: 20px solid #e4b300 !important;
}
.calendar-content{
color: #0077ff !important;
}
.calendar-months, .calendar-years{
color: #0077ff !important;
}
.calendar-months>.months-list li.active, .calendar-years>.years-list li.active{
    background:  linear-gradient(-135deg,#313e9e,#0077ff) !important;
}

.menu2{width:100%;float:left;padding: 15px 10px;background:#fff;display:none;}
.togglemenu2{width:10%;float:left;}
.togglemenu2 a{width: auto;float: left;margin-top: 10px;}
.togglemenu2 a .fa{color:#000;}
.logomenu2{width:80%;float:left;}
.logomenu2 a{width:auto;margin:0 auto;display:table;}
.logomenu2 img{width:100%;float:left;}
.moremenu2{width:10%;float:left;}
.moremenu2 .fas{width:auto;float:right;margin-top: 15px;}
.chartimg{width:100%;float:left;padding-top:10px;}
.chartimg img{width:100%;float:left;}

/*update*/
/*login-page*/
.longinBg{min-height:0;background:#f8fafb}
.LoginLOgo{width:400px;display:table;margin:10% auto;background:#fff;padding:10px;border-radius:10px;box-shadow: 0 0px 3px rgb(0 0 0 / 16%), 0 0px 3px rgb(0 0 0 / 23%);}
.LoginLOgo img{width:50px;margin:0 auto;display:table;}
.loginContentdrb{width:100%;float:left;border:1px solid #ccc;}
.loginContentdrb h2{width:100%;float:left;text-align:center;background:#6f6486 ;color:#fff;padding:5px;font-size:25px;margin-bottom:0;font-family: Poppins-Medium;}


.ErrMsg{width:100%;float:left;text-align:center;color:#ff0000;display:none;}
.userLogContdrbbtn .button {padding: 8px 24px;width:100%;color: #fff;font-size: 18px;text-align: center;
letter-spacing: 1px;border: 0;cursor: pointer;transition: all 0.15s ease;border-radius:30px;margin: 0 auto;
display: table;height:50px;}
.buttonBlue {background: rgb(0, 143, 251);}
.buttonBlue:hover {opacity:0.5;}
.loginBg{background:#fcd8b6;}
.loginContentFrm h1{width:100%;float:left;text-align:center;color:#dc6f09;}
.workspaceSelect {width: 250px;float:left;}
.checkBoxSelect {width: 450px;float:left;}
.checkboxOptions {width: 100%;overflow-x: auto;max-height: 200px;}
/*login-page*/



/*pop-up*/
.close{width:15px;height:15px;position:absolute;top:-15px;right:-18px;color:#fff;background-image: url('../img/icons/popclose.png');}
.overlay{background:rgba(0,0,0,0.6);position:fixed;width:100%;height:100%;z-index:99;top:0;left:0;display:none;}
.GrapOverlay{width:auto;float:left;}
.lBoxCont{width:100%;float:left;}
.lBoxCont img{width:100%;float:left;}
/*1600*/
.userLog{width:100%;float:left;}
.userLog h2{font-size:18px;padding:5px 0;text-align:center;font-weight:bold;}
.userLog span{width:100%;float:left;text-align:center;margin:5px 0;}
.userLog .newusrform{width:50%;margin:0 auto;display:table;padding:10px 20px;float:none;}
.userLog label{width:100%;float:left;margin:3px 0;}
.userLog div.forCenter{width: auto;margin: 10px auto;display: table;}
.userLog .frgtPsw {width: 100%;display:inline;color: #000;padding: 2px 0;}
.userLogBtnCont2{width: auto;float: left;margin-top: 10px;}
.userLogBtnCont2 button {width: 100%;float: left;padding:5px 25px;margin: 0 3px;cursor: pointer;
border: none;color: #fff;text-transform: uppercase;background: #24a46d;border-radius:10px;}
#lblLoginError{width:100%;float: left;padding:2px 0;font-size:10px;color:#eb0000;}
.ErrmSg{border:5px solid #ff0000;border-radius:10px;}
.warning{border-radius:10px;border:2px solid #ff0000;}
.sucess{border-radius:10px;border:2px solid #008000;}
.enqForm{width:100%;float:left;padding:10px;border-radius: 0 0 10px 10px;background:#e1e1e1;}
.lightBox3{width:450px;background:#ffffff;position:fixed;left:50%;top:50%;z-index:99999;border-radius:10px;display:none;
  border:5px solid #34c38f;}
.lightBox2{width:300px;background:#ffffff;position:fixed;left:50%;top:50%;z-index:99999;border-radius:10px;display:none;}

.lightBox6{width:450px;background:#ffffff;position:fixed;left:50%;top:50%;z-index:99999;border-radius:10px;border:5px solid #34c38f;display:none;}

.lightBox8{width:450px;background:#ffffff;position:fixed;left:50%;z-index:99999;border-radius:20px;border:5px solid #34c38f;display:none;}

  
  .userLog2{width:100%;float:left;padding:5px;}
.userLog2 h2{font-size:18px;padding:5px 0;text-align:left;font-weight:bold;}
.userLog2 p{width:100%;float:left;text-align:center;margin:5px 0;}
  
  .userLog2 div.Btninfo {
    width: auto;
    margin: 10px auto;
    display: table;
}
 .Btninfo button {
    width:auto;
    float: left;
    padding: 5px 25px;
    margin: 0 3px;
    cursor: pointer;
    border: none;
    color: #fff;
    text-transform: uppercase;
    background: #009e65;
 border-radius: 10px;}
  .Btninfo button:hover{opacity:0.5;}
  
.userlogInputDiv{width:100%;float:left;overflow-y:auto;max-height:130px;}
.userlogInput{width:100%;float:left;}
.userlogInput input{width:auto;float:left;margin:10px;}
.userlogInput label{width:auto;float:left;}
.TableContainer{width:100%;float:left;margin-top:20px;}
/*update*/

/*loader*/
#divLoading{width:100%;height:100%;position:fixed;right:0;top:0;background:rgba(0,0,0,0.6);z-index:100000;filter: alpha(opacity=70);display:none;}
#centerVal{width:180px;position:absolute;left:50%;top:50%;margin:-50px 0 0 -50px;}
.lds-roller {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-roller div {
animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
transform-origin: 40px 40px;
}
.lds-roller div:after {
content: " ";
display: block;
position: absolute;
width: 7px;
height: 7px;
border-radius: 50%;
background: #0A7CEE;
margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
top: 63px;
left: 63px;
}
.lds-roller div:nth-child(2) {
animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
top: 68px;
left: 56px;
}
.lds-roller div:nth-child(3) {
animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
top: 71px;
left: 48px;
}
.lds-roller div:nth-child(4) {
animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
top: 72px;
left: 40px;
}
.lds-roller div:nth-child(5) {
animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
top: 71px;
left: 32px;
}
.lds-roller div:nth-child(6) {
animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
top: 68px;
left: 24px;
}
.lds-roller div:nth-child(7) {
animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
top: 63px;
left: 17px;
}
.lds-roller div:nth-child(8) {
animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
top: 56px;
left: 12px;
}
@keyframes lds-roller {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

/*update2*/

.cateFilterCont3{width:5%;padding:0 10px;background:#fff;box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  transition: .2s ease-in;z-index:9;display: inline-block;position:fixed;height:100%;display:none; }
/*update2*/
.loginRgt{width:60%;float:left;height:100%;background: rgb(0, 143, 251);}
.loginLft{width:40%;float:left;background:#fff;height:100%;padding: 75px 0;}
.loginRgt img{width: 600px;margin: 0 auto;display: table;}

.imgSec{ width: 475px;
  height: 460px;
  border-radius: 266px;
  padding: 10px;
  margin: -25% auto;
}

.loginContentFrm{width:430px;padding: 10px;margin:0 auto;}
.userLogRgt{width:100%;float:left;}
.userLogLft img{width:auto;margin:0 auto;display:table;}
.enqFormdrb {width:100%;margin:0 auto;display:table;float:none;padding:10px 0 0 0;}
.enqFormdrb h2{width:100%;text-align:center;float:left;color:#1d5f4a;}
.userLogContdrb {width:100%;float: left;margin:8px 0;}
.userLogContdrb div {width: 100%;float: left;}
.userLogContdrb input {width: 100%;float: left;border:none;outline:0;
border:1px solid #ccc;font-size:14px;letter-spacing: 1.6px;background: none;padding: 10px 10px;border-radius:10px;outline:none;}
.newUserTxt:focus {border: 2px solid #6e6e6e;}

.loginContentFrm h3{width:100%;float:left;font-size:25px;}
.loginContentFrm p{width:100%;float:left;color: #b3b3b3;}
.userLogPsw{width:100%;float:left;margin-top: 10px}
.userLogPsw label{width:auto;float:left;}
.userLogPsw label input{width:20px;height:20px;float:left;margin-right:5px;}
.userLogPsw span{width:auto;float:right;}
.userLogPsw span a{color:#888;}
.userLogPsw span a:hover{color:#ef2365;}
.userLogContdrbbtn{width: 100%;float: left;margin: 8px 0;padding-top:25px;}
.loginLft img{width:50px;margin:0 auto;display:table;}

	.social-login a {text-decoration: none;position: relative;text-align: center;color:#999999;margin-bottom: 10px;margin-left: 5px;
    width: 50px;height: 50px;border-radius: 50%;display: inline-block;border:1px solid #999999;}
	.social-login a:hover{background:#34c38f;color:#fff;border:none;}
	.social-login a span {position: absolute;top: 20%;left: 35%;font-size: 20px;}
	.loginChoice{width:100%;float:left;padding:20px 0;}
	.loginRgtInner{width:auto;margin:25% auto 0;display:table;}
	/* .loginRgtInner img{ width: 370px;
    margin: 0 auto;
    display: table;
    padding-top: 24px;
    margin-left: 50px;
    margin-top: 60px;
    border-radius: 63px;
} */
.loginRgtInner img{ width: 370px;
  margin: 40 auto;
  display: table;
  padding-top: 0;
  margin-left: 44px !important;
  margin-top: 36px;
  border-radius: 63px;
}
	.loginRgtInner h2{width:100%;float:left;text-align:center;font-size:35px;color:#fff;font-family: roboto-bold-webfont;padding-top:5px;}
	.loginRgtInner p{width:100%;float:left;text-align:center;font-size:20px;color:#fff;}
	.loginRgtInner a:hover{ color:#000;
   background: #fff;}
	.loginRgtInner a{border: 1px solid #fff;background:none;padding: 10px 20px;font-size: 15px; border-radius: 50px;color: #fff;}
	.loginRgtInner .signBtn{width:auto;margin:0 auto;display:table;padding-top:20px;}
	/*404*/
	.errorContent{width:100%;float:left;}
	.errorContentInner{width:50%;margin:2% auto;display:table;}
	.errorContentInner img{width:auto;margin:0 auto;display:table;}
	.errorContentInner h1{width:100%;float:left;text-align:center;font-size:60px;margin-top:10px;}
	.errorContentInner p{width:100%;float:left;text-align:center;margin-top:15px;}
	.errorContentInner span{width:100%;float:left;text-align:center;font-size:40px;margin-top:10px;
	  font-family:Poppins-Regular;color:#0A7CEE;}
	  
  .noDataCenter{width:100%;float:left;text-align:center;font-size: 24px;margin: 12% 0;}

.userLogHead h2 {width: 100%;float: left;font-size: 23px;margin: 0%;}	  
.contentclouds{width:100%;float:left;}
.userLogHead{width:100%;float:left;padding:5px 0px;}
.userLogHead h2{width:auto;float:left;font-size:23px;margin: 0%;font-family: Poppins-Medium;}
.userLogHead small{width:100%;float:left;font-size:12px;color:#5f5f5f;margin-top:5px;}
.userLogform{width:100%;float:left;padding:10px;background:#fff;}
.userLogformLft{width:100%;float:left;}
/* .userLogformLft span{width:100%;float:left;} */
.userLogCont2{width:80%;float:left;margin:5px 0 0;}
.userLogCloud div{width:100%;float:left;}
.userLogCloud div label{width:100%;float:left;padding:0 0 2px;}
.userLogCloud h4{width:100%;float:left;padding:0 0 2px;}
.userLogCloud input{font-family: Poppins-Regular;width:100%;float:left;padding:10px 5px;border:1px solid #999999;background:transparent;outline:0;border-radius:5px;}
.userLogCloud select{width:100%;float:left;padding:10px 5px;border:1px solid #999999;background:transparent;outline:0;border-radius:5px;}
.userLogCloud2 textarea{width:100%;float:left;padding:10px 5px;border:1px solid #999999;background:transparent;outline:0;border-radius:5px;border-radius:5px;}
.userLogCloud {width:50%;float: left;padding:5px;}
.userLogformLft ul{width:100%;float:left;}
.userLogformLft ul li{width:100%;float:left;}
.userLogformLft ul li .userLogCloud2{width:33.33333%;float:left;padding: 5px;}
.userLogformLft ul li .userLogCloud4{width:25%;float:left;padding: 5px;}
.userLogformLft ul li .userLogCloud3{width:33.33333%;float:left;padding: 5px;}
.userLogformLft ul li .userLogCloud44{width:25%;float:left;padding: 5px;}
.userLogformLft ul li .userLogCloud2 div{width:100%;float:left;}
.userLogformLft ul li .userLogCloud4 div{width:100%;float:left;}


.userLogformLft ul li .userLogCloud6{width:33.33333%;float:left;padding: 5px;}
.userLogformLft ul li .userLogCloud6 div input{font-family: Poppins-Regular;width:93%;float: left;padding: 10px 5px;border: 1px solid #999999;
    background: transparent;outline: 0;border-radius:5px;}


.userLogCloud2 .logoDiv{width:auto;float:left;margin-top: 18px;padding: 5px;}
.userLogCloud2 span img{width:35px;float:left}
.userLogCloud2 small{width: auto;float: left;position: relative;top: -10px;}
.userLogCloud4 .logoDiv{width:auto;float:left;margin-top: 18px;padding: 5px;}
.userLogCloud4 span img{width:35px;float:left}
.userLogCloud4 small{width: auto;float: left;position: relative;top: -10px;}
.userlogSelect{width:100%;float:left;font-size: 12px;}
.userlogSelect1{width: 140px;
  font-size: 12px;
  float: left;
  margin: 0 5px;
  border-radius: 5px;
  outline: none!important;
  background: #fff;
  box-shadow: 1px 3px 5px rgb(0 0 0 / 10%);
  border: 1px solid #e1e1e1;}
.iconStyle{
    padding: 0 5px;
    color: #0A7CEE;
    width: auto;
    float: left;
    font-size: 14px;
  }
  .userLogformLft ul li .userLogCloud2 div label{width:auto;float:left;}
  .userLogformLft ul li .userLogCloud4 div label{width:auto;float:left;}
  .userLogformLft ul li .userLogCloud3 div label{width:100%;float:left;}
  .userLogformLft ul li .userLogCloud44 div label{width:100%;float:left;}
.userLogformLft ul li .userLogCloud2 div input{font-family: Poppins-Regular;width: 100%;float: left;padding: 10px 5px;border: 1px solid #999999;
    background: transparent;outline: 0;border-radius:5px;}
    .userLogformLft ul li .userLogCloud4 div input{font-family: Poppins-Regular;width: 100%;float: left;padding: 10px 5px;border: 1px solid #999999;
      background: transparent;outline: 0;border-radius:5px;}
.userLogformLft ul li .userLogCloud3 div input{font-family: Poppins-Regular;width: 100%;float: left;padding: 10px 5px;border: 1px solid #999999;
  background: transparent;outline: 0;border-radius:5px;}
  .userLogformLft ul li .userLogCloud44 div input{font-family: Poppins-Regular;width: 100%;float: left;padding: 10px 5px;border: 1px solid #999999;
    background: transparent;outline: 0;border-radius:5px;}
.userLogBtnCont3{width:auto;padding: 10px 0 0 0;margin: 0px auto;display: table;border:none;}
.userLogBtnCont3 button{border-radius: 5px;min-width:100px;float:left;padding:10px 10px;margin:0 5px 5px 0;cursor:pointer;border:none;color:#fff;text-transform:uppercase;background:#009e65;}
.userLogBtnCont3 button:hover{opacity:0.6}
.GreenC:hover{opacity:0.6}
.RedC:hover{opacity:0.6}
.BlueC:hover{opacity:0.6}
.userLogCloud2 button{border-radius: 5px;min-width:100px;float:left;padding:10px 10px;margin:2px 1px;cursor:pointer;border:none;color:#fff;text-transform:uppercase;background:#009e65;}
.userLogCloud2 button:hover{background:#5ad6a9;color:#108f00;}
.userLogCloud button{border-radius: 5px;min-width:100px;float:left;padding:10px 10px;margin:2px 1px;cursor:pointer;border:none;color:#fff;text-transform:uppercase;background:#009e65;}
.userLogCloud button:hover{background:#5ad6a9;color:#108f00;}
.TableReact{width:100%;float:left;padding: 10px;background: #fff;}
#selTables{width:100%;float:left;padding: 5px;border: 1px solid #ccc;}
.MuiInputBase-input{font-size: 14px!important;}

.BlueBg{background:#5e72e4!important}
.RedBg{background:#f62d51!important}
.GreenBg{background:#052c7a!important}
.GreyBg{background:#696969!important}
.GreyBgg{background:#808080!important}
.SubTotalBg{background:#C8C8C8!important;font-weight: bold!important;}
.GreenBgg{border: 1px solid #052c7a!important;color:#052c7a!important}
.RedBgg{border: 1px solid #ff0000!important;color:#ff0000!important}

.headBtomMenu{width:auto;float:left;padding:5px;background: #fff;border-radius:2px;}
.headBtomMenu li{width:auto;float:left;}
.headBtomMenu li{width:auto;float:left;margin:0 2px;}	
.headBtomMenu li a{width:auto;float:left;color:#5f5f5f;}	
.headBtomMenu li span{padding:5px}	
.headBtomMenu li a:hover{cursor: default;}	

.ContainerTable{width:100%;float:left;padding:10px;background: #fff;}
.tableSearch {width: auto;float:right;margin-top:10px;}
.sportSrch {width: 100%;float: left;}
.searchInput {width: 250px;float: left;background: url(../img/icons/Search.png);background-position: 95% 6px;
  background-repeat: no-repeat;
  padding: 6px 35px 6px 5px;border: 1px solid #ccc;outline: 0;border-radius:10px;color: #1d5f4a;background-color: #fff;}
#myInput {width: 250px;float: left;background: url(../img/icons/Search.png);background-position: 95% 6px;
    background-repeat: no-repeat;
    padding: 6px 35px 6px 5px;border: 1px solid #ccc;outline: 0;border-radius:10px;color: #1d5f4a;background-color: #fff;}
.searckLink {width: auto;float: left;display: none;}
.searckLink span{width: auto;float:left; font-family: Poppins-Medium;padding: 10px;border-radius: 5px;}



.warningmsg{width:100%;float:left;padding:10px;background:#ff0000;}
.warningmsg span{width:auto;margin:0 auto;display:table;float:none;}
.warningmsg span.fa{color:#fff}
.warningBtn button{width: 100%;float: left;padding:5px 25px;margin: 0 3px;cursor: pointer;
border: none;color: #fff;text-transform: uppercase;background: #ff0000;border-radius:10px;}


.sucessmsg{width:100%;float:left;padding:10px;background:#008000;}
.sucessmsg span{width:auto;margin:0 auto;display:table;float:none;}
.sucessmsg span.fa{color:#fff}
.sucessmsgBtn button{width: 100%;float: left;padding:5px 25px;margin: 0 3px;cursor: pointer;
border: none;color: #fff;text-transform: uppercase;background: #008000;border-radius:10px;}


text{word-break: break-all;}

.ButtonArea{width:auto;float:left;}


.ButtonDoc {width: auto;float: left;margin: 0 2px;padding:5px;
    font-size: 12px;background:#e7eaf5;}
.ButtonDoc a{color:#5f5f5f;font-family: Poppins-Medium;}
.ButtonDoc a:hover{opacity:0.5;}

.ButtonDocBU {width: auto;float: left;margin: 2px 2px;padding:5px;
  font-size: 12px;background:#009e65;}
.ButtonDocBU a{color:#fff;font-family: Poppins-Medium;}
.ButtonDocBU:hover {
  background: #5ad6a9;
  color: #fff;
}

.lightBox4 {width:600px; background: #ffffff;position: fixed;left:49%;z-index: 99999;
    border-radius:20px;display:none;}
	.lightBox5 {width:1100px; background: #ffffff;position: fixed;left:49%;z-index: 99999;
    border-radius:20px;display:none;}
    .lightBox7 {width:420px; background: #ffffff;position: fixed;left:49%;z-index: 99999;
      border-radius:20px;display:none;}
.userLogpreview {width: 100%;float: left;border-radius:20px;padding:10px;}
.chartimg2{width: 100%;float: left;}
.chartimg2 img{width: 100%;float: left;}


.ContainerChart1{width:100%;float:left;padding:0;max-height:500px;overflow-y:auto;}
.ContainerChart2{width:100%;float:left;padding:0;max-height:500px;}
.PreviewHead {width:100%;float:left;margin-bottom:5px;}
.PreviewHead h2{width:auto;float:left;font-size:16px;margin: 0 10px;}
.PreviewHead .CancelBtn {
    width: auto;
    float: left;
    margin-top: 5px;
    position: absolute;
    top: -20px;
    /* left: -3px; */
    right:-13px;}
.PreviewHead .CancelBtn1 {
  width: auto;
  float: left;
  margin-top: 5px;
  position: absolute;
  top: -25px;
  /* left: -3px; */
  right:-20px;}
.PreviewRgt{width:auto;float:right;}
.PreviewRgt .far{width:auto;float:left;font-size: 20px;margin-right: 10px;color:#ff0000;}
.PreviewHeadinnr{width:75%;float:left;}
.PreviewHeadinnr h4{width:100%;float:left;text-align:center;font-family: Poppins-Medium;}
.graphSecContMain{width:auto;float:right;}
	
	.toggleSwitch span span {display: none;}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    /* margin-left: 50px; */
    cursor: pointer;
    width: 40px;
    margin-top:16px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -2px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 2px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #fff;
    opacity: 1;
    left: 45%;font-family: Poppins-Medium;
  }
  .toggleSwitch > span:before {
    content: '';
    display: block;
    width: 100%;
    height: 150%;
    position: absolute;
    left: 50px;
    top: -6px;
    background-color:#2966bd;
    border: 1px solid #2966bd;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;width: 100px;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: 41px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #052c7a;
    box-shadow: inset 0 0 0 30px #052c7a;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;font-family: Poppins-Medium;
  }
}


/*  End Toggle Switch  */

table {
  border-collapse: collapse;float:left;
  width: 100%;background:#fff;
}
th {
  text-align: left;
padding:7px;word-break: break-all;}
td{
  text-align: left;
  padding:7px;word-break: break-all;border-bottom:1px solid #ccc;color:#5f5f5f;
}

/* .userLogBtnCont3 small{width: auto;float: left;} */
/* .userLogBtnCont3 small.fa{font-size:10px;padding:5px;}
.userLogBtnCont3 small{width:10px;height:10px;border-radius:100px;border:1px solid #5e72e4;padding:3px;background:#5e72e4;position:relative;} */
.graphPop4 {width:450px;float: left;position: absolute;box-shadow: 0 10px 20px rgb(0 0 0 / 16%), 0 5px 20px rgb(0 0 0 / 23%);
  z-index: 10;right:45px;border:1px solid #C0C0C0!important;padding:10px;border-radius:20px;background:#fff;display: none;}
.graphPop7 {width: 340px;
  float: left;
  position: absolute;
  box-shadow: 0 10px 20px rgb(0 0 0 / 16%), 0 5px 20px rgb(0 0 0 / 23%);
  z-index: 99;
  top: 40px;right:0;
  border: 1px solid #C0C0C0!important;
  padding: 5px;
  border-radius: 20px;
  background: #fff;display:none;}
.graphPop6 {width:450px;float: left;position: absolute;box-shadow: 0 10px 20px rgb(0 0 0 / 16%), 0 5px 20px rgb(0 0 0 / 23%);
    z-index: 10;right:45px;border:1px solid #C0C0C0!important;padding:10px;border-radius:20px;background:#fff;display: none;}
.graphPop5 {width: 350px;float: left;position: absolute;box-shadow: 0 10px 20px rgb(0 0 0 / 16%), 0 5px 20px rgb(0 0 0 / 23%);
    z-index: 10;background: #ededed;right:20px;top:182px!important;display:none;border:2px solid #212c2c;}
.graphPop4 img {margin:11px -12px 0 0;position:absolute;top:0;right:0;width: auto;}
.graphPop6 img {margin:11px -12px 0 0;position:absolute;top:0;right:0;width: auto;}

.BlueC{color:#5e72e4;background:#5e72e4;color:#fff;padding:5px;border-radius: 5px;}
.RedC{color:#f62d51;background:#f62d51;color:#fff;padding:5px;border-radius: 5px;}
.GreenC{color:#4fa744;background:#4fa744;color:#fff;padding:5px;border-radius: 5px;}
.GreyG{color:#696969;}

/* .userLogBtnCont3 .fa:hover{opacity:0.3;}
.userLogBtnCont3 .fas:hover{opacity:0.3;} */

.tableSearchreport{width: auto;float:left;}
.ButtonArea{width: auto;float:right;}
.chartdiv{width:100%;float:left;padding: 5px 10px;background: #fff;}

.TblError{width:100%;float:left;background: #fff;}
.TblError span{width:100%;float:right;color:#4fa744;padding: 0 10px;text-align: right;font-size:15px;font-family: Poppins-Medium;}
.TblError a{width:100%;float:right;color:#5e72e4;padding: 0 10px;text-align: right;font-size:15px;font-family: Poppins-Medium;}
.TblError a:hover{color:#FF0000;}

.userLogHeadinner{width:50%;float:left;}
.userLogHeadinner h2{font-size:18px;color:#0A7CEE;font-weight: bold;}
.usereset{width:auto;float:right;}
.usereset button {
    border-radius: 5px;
    min-width: 100px;
    float: left;
    padding: 7px 10px;
    margin: 0 1px;
    cursor: pointer;
    border: none;
    color: #fff;
    text-transform: uppercase;
    background: #5e72e4;
}

.userLogHeadinnerRgt{width:auto;float:right;padding-top:10px}

.cateinner li:hover{background:#f5f6f8}
.Dateform{width:100%;float:left;background:#0A7CEE;padding:5px;}
.toogleSwitch{width:100px;float:left;}

.userLogformTop{width:710px;float:left;}
.userLogformTop ul{width:100%;float:left;}
.userLogformTop ul li{width:33.33333%;float:left;}

/*update*/
.userLogformTop2{width:710px;float:left;position:relative}
.userLogformTop2 ul{width:100%;float:left;}
.userLogformTop2 ul li{width:25%;float:left;}

.userLogformTop3{width:710px;float:left;position:relative;}
.userLogformTop3 ul{width:100%;float:left;}
.userLogformTop3 ul li{width:20%;float:left;}
.userLogBtnTop3 a{width:auto;float:left;}


.userLogBtnTop3 {padding: 3px 0 0 0;float:left;}
.userLogBtnTop3 button {
    border-radius: 5px;
    min-width:90px;
    float: left;
    padding: 10px 5px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    color: #fff;font-size:12px;
    text-transform: uppercase;
    background: #009e65;
}


.userLogBtnTop a{width:auto;float:left;
    position: relative;
    top: -5px;}

/*update*/



.userLogCloudtop {width:100%;float: left;padding: 5px;}
 .userLogCloudtop input {
    font-family: Poppins-Regular;
    width: 100%;
    float: left;
    padding: 10px 5px;
    border-radius: 5px;outline:none!important;background:#fff;border:none!important;}

 .userLogCloudtop select {
    font-family: Poppins-Regular;
    width: 100%;
    float: left;
    padding: 10px 5px;
    border-radius: 5px;outline:none!important;background:#fff;border:none!important;}
	
.userLogBtnTop{width:auto;float: right;padding: 6px 0 0 0;}
.userLogBtnTop button{border-radius: 5px;min-width:100px;float:left;padding:10px 5px;margin:0 5px;cursor:pointer;border:none;color:#fff;text-transform:uppercase;background:#052c7a;}
.userLogBtnTop button:hover{background:#0A5AFF;color:#fff;}
#myfileExport {border-radius: 5px;min-width:130px;float:left;padding:8px 10px;margin:0 5px;cursor:pointer;border:none;color:#fff;text-transform:uppercase;background:#052c7a;font-size:12px;}
#myfileExport:hover{background:#0A5AFF;color:#fff;}
.UseformNew{width:100%;float:left;box-shadow: 0px 1px 23px 7px rgba(215,209,209,0.75);
-webkit-box-shadow: 0px 1px 23px 7px rgba(215,209,209,0.75);
-moz-box-shadow: 0px 1px 23px 7px rgba(215,209,209,0.75);}

.userLogBtnCont3 small{width:auto;float:left;}


.graphSecCont{padding:5px;border: 1px solid #B6B6B6;border-radius:5px;float:left;background:#f1f1f1}
.graphSecCont ul{width:100%;float:left;}
.graphSecCont ul li{width:auto;float:left;border:1px solid #ccc}
/* .graphSecCont ul li:hover{background: #34c38f;} */
.activeChart{background: #34c38f;}
.graphSecCont a{width:auto;float:left;margin:0 5px;color:#5f5f5f}
.graphSecCont a .fas{font-size:18px;margin-top:2px;}

.CancelBtn .fas{font-size:25px;color:#fff000;width:20px;height:20px;text-align:center;}
.CancelBtn1 .fas{font-size:25px;color:#fff000;width:20px;height:20px;text-align:center;}
.PreviewHead h2{width:100%;float:left;}


/*Dashboard*/
.DashboardCont{width:100%;float:left;padding:10px;}
.DashboardContinr{width:100%;float:left;margin-top: 10px;}
.DashboardContinr h3{width:auto;float:left;margin-top:5px;color:#0A7CEE}
.DashRgt{width:auto;float:right;}
.DashcontInner{width:auto;float:left;margin:0 5px; padding: 8px 5px;
    border-radius: 5px;
    outline: none!important;
    background: #fff;
   box-shadow: 1px 3px 5px rgb(0 0 0 / 10%);border:1px solid #e1e1e1;    position: relative;
}
.DashcontInner1{width:auto;float:left;}

	.DashcontInner .fa{width:auto;float:left;color:#0A7CEE;font-size:14px;}
	.DashcontInner .fas{width:auto;float:left;color:#0A7CEE;font-size:14px;margin-top: 3px;
    padding: 0 5px;}
  .DashcontInner select{width:auto;float: left;}
  .DashcontInner label{width:auto;float: left;padding: 0 5px 0 0;}
  #DateRange:hover{color: #0A7CEE;cursor: pointer;}

.DashcontDateCont{width:auto;float:left;}
.DashcontDateCont label{width:auto;float:left;
    margin: 8px 5px 0px;color:#0A7CEE}
.DashcontDate{width:auto;float:left; border-radius: 5px;margin-top: 3px;
    outline: none!important;  border-radius: 5px;
    background: #fff;box-shadow: 1px 3px 5px rgb(0 0 0 / 10%);}
.DashcontDate ul{width:100%;float:left;}
.DashcontDate ul li{width:auto;float:left;}
.DashcontDate ul li a{width:auto;float:left;padding:8px 10px;font-size:12px;color:#000;border-radius: 5px;}
.DashcontDate ul li a:hover{background:#0A7CEE;color:#fff}
.DashcontDate ul li .Active{background:#0A7CEE;color:#fff}

.DashChartCont{width:100%;float:left;padding-top:20px;}
.ChartLft{width:60%;float:left;border-radius:5px;background:#fff;border:1px solid #e1e1e1;height:320px;margin-right:20px;}

.ChartLft h4{width:100%;float:left;font-size:16px;border-bottom:2px solid #f1f1f1;padding:10px;}
.ChartLft img{width:100%;float:left}
.ChartRgt{width:38%;float:left;border-radius:5px;background:#fff;border:1px solid #e1e1e1;height:320px;}
.ChartRgt h4{width:100%;float:left;font-size:16px;border-bottom:2px solid #f1f1f1;padding:10px;}
.ChartRgt img{width:auto;float:left}
.ChartLftBtm{width:100%;float:left;padding:10px;}
.DashBetSec{width:100%;float:left;padding-top:20px;}
.DashBetSecLft{width:49%;float:left;background:#fff;border-radius:5px;padding:10px;border:1px solid #e1e1e1;margin-right:20px;cursor:pointer;}
.DashBetSecRgt{width:49%;float:left;background:#fff;border-radius:5px;padding:10px;border:1px solid #e1e1e1;cursor:pointer;}
.Dashlogo{width:auto;float:left;}
.Dashlogo span{width:auto;float:left;margin-right:10px;}
.Dashlogo span img{width:100%;float:left;}
.Dashlogo div{width:auto;float:left;}
.Dashlogo div small{width:auto;float:left;color:#a09e9e;}
.Dashlogo div h3{font-size:14px}
.DashlogoRgt{width:75px;float:right;}
.DashlogoRgt span{width:100%;float:left;font-family: Poppins-Medium;text-align:center;cursor:pointer}
.DashlogoRgt span:hover{color:#5f63f2}
.DashlogoRgt small{width:100%;float:left;text-align:center;color:#a09e9e;}
.DashbetTop{width:100%;float:left;padding:10px 0;border-bottom:2px solid #f1f1f1;}
.DashbetBtm{width:100%;float:left;padding:15px 0 0;}
.DashbetBtm ul{width:100%;float:left;}
.DashbetBtm ul li{width:20%;float:left;}
.DashbetBtm ul li:last-child{margin-right:0px;}
.DashbetBtm ul li img{width:auto;margin:0 auto;display:table;border:1px solid #e1e1e1;background:#ccc;padding:5px;border-radius:5px;}
/* .DashbetBtm ul li img:hover{border:1px solid #f6ae00;background:#f1f1f1!important;} */
/*.DashbetBtm ul li img:hover{border:1px solid #f6ae00;-ms-transform: scale(1.2);-webkit-transform: scale(1.2);transform: scale(1.2);}*/
.bg1{background:#def7f0!important}
.bg2{background:#e0f0ff!important}
.bg3{background:#feeedb!important}
.bg4{background:#ffe5e5!important;margin-top:3px!important;}
.bg5{background:#f6ffd1!important}
.DashbetBtm ul li span{width:100%;float:left;text-align:center;font-size:13px;color:#000}
.DashbetBtm ul li small{width:100%;float:left;font-size:9px;padding-top:5px;text-align:center;color:#a09e9e}
.DashBetSecLft {transition: transform .2s;}
.DashBetSecLft:hover {-ms-transform: scale(0.95);-webkit-transform: scale(0.95);transform: scale(0.95);}
.DashBetSecRgt:hover {-ms-transform: scale(0.95);-webkit-transform: scale(0.95);transform: scale(0.95);}
.DashBetSecCont{width:100%;float:left;}


.radioBtn{width:100%;float:left;}
.radioBtn label{width:auto;float:left;margin-top:5px;margin-right: 5px;}
.radioBtn1{width:auto!important;float:left}

.container {
  display: block;
  position: relative;
  padding-left:25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size:14px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color:#ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #052c7a;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.AdhaarDtls{width:100%;float:left;border:1px solid #ccc;background:#f5f6f8;padding:5px;border-radius: 5px;}
.AdhaarDtls h4{width:40%;float:left;padding:2px;margin-top:3px;text-align: center;}
.AdhaarDtls ul{width:100%;float:left;}
.AdhaarDtls ul li{width:100%;float:left;margin-top:5px;}
.AdhaarDtls ul li label{width:36%!important;float:left;font-size:11px;}
.AdhaarDtls ul li span{width:auto;float:left;margin-left:10px;font-size:11px;word-break:break-word}
.userLogCloud2 .logoDiv2{width:170px;float: left;padding: 5px;background:#e1e1e1;}
.logoDiv2 img{width:25px!important;margin:0 auto;display:table;float:none!important;}


.userLogCloud5{width:100%;float:left;border:1px solid #ccc;padding:10px;position:relative;
    background: #f1f1f1;}
.userLogCloud5 h5{width:auto;float:left;position:absolute;top: -9px;
    background: #fff;}
.LottoRst{width:460px;margin:0 auto;display:table;float:none;}
.userLogCloud5 input{width:50px;float:left;border:1px solid #ccc;padding:10px;text-align:center;margin:2px;font-size:16px;}
.userLogCloud5 span{width:35%;float:left;margin-top:10px;}
.userLogCloud6 input{width:90%;float:left;border:1px solid #ccc;padding:10px;text-align:center;margin:2px;font-size:16px;}



.LottoRst2{width:65%;float:left;}

.userLog2 ul{width:100%;float:left;}
.userLog2 ul li{width:100%;float:left;margin-bottom:5px;padding:2px 10px;}
.userLog2 ul li label{width:40%;float:left;}
.userLog2 ul li span{width:60%;float:left;word-break:break-all;}
.userLog2 ul li span input{width:100%;float:left;border:1px solid #ccc;padding:5px;}


.tableSearchreportpop {width:100%;float: left;}
/* by thamil */
.ReactTable .rt-thead .rt-tr {
  text-align: left;
}
@media screen and (max-width: 1600px) and (min-width:1366px)
{	
/*Home*/

/*Inner*/
}
/*1280*/
@media screen and (max-width:1365px) and (min-width:1280px)
{
/*Home*/

}
/*1024*/
@media screen and (max-width: 1079px) and (min-width: 1024px)
{
/*Home*/
.selRslt ul {width:100%;}
.inputLabel {width: 22%;}

.userLogformTop {width: 465px;}
.userLogHead small {float: left;font-size: 10px;}
.headBtomMenu li a {font-size: 12px;}

.lightBox5 {width:800px; }

.ChartLft {margin-right: 15px;}
.DashBetSecLft {margin-right: 15px;}

.userLogCloud div label{font-size:12px;}
.userLogformLft ul li .userLogCloud2 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud4 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud3 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud44 div label{font-size:12px;}

.userLogformTop3 {width:100%;}
.userLogformTop2 {width:100%;}
.userLogBtnTop3{width: auto;margin: 0 auto;display: table;float:none!important;}
}
/*800*/
@media screen and (max-width: 1023px) and (min-width: 800px)
{
/*Home*/
.cateFilter li a {padding: 10px 0;font-size: 10px;}
.selRslt ul {width:100%;}
.cateFilter ul li a{font-size:10px;}
.inputLabel {width:50%;margin: 3px 0px;}
.inputDate {width:50%;margin: 3px 0px;}

.loginContentFrm{width:100%;}
.userLogformLft ul li .userLogCloud2 {width:100%;}
.userLogformLft ul li .userLogCloud4 {width:100%;}
.userLogformLft ul li .userLogCloud3 {width:100%;}
.userLogformLft ul li .userLogCloud44 {width:100%;}
.userLogCloud {width:100%;}

.toggleSwitch {margin: 10px 0;}


.userLogformTop {width: 100%;}

.userLogBtnTop {width: auto;margin:0 auto;display:table;float:none;}
.userLogHead small {float: left;font-size: 10px;}

.headBtomMenu li a {font-size: 12px;}
.userLogHeadinner {width:50%;}

.lightBox5 {width:700px; }
.ChartLft {margin-right: 15px;}
.DashBetSecLft {margin-right: 15px;}
.DashBetSecLft,.DashBetSecRgt{width:100%;margin-bottom: 5px;}

.userLogBtnTop3{margin: 0 auto;display: table;float:none!important;}
}

/*640*/
@media screen and (max-width:800px) and (min-width: 640px)
{
/*Home*/
.loginRgtInner {margin: 0% auto 0;padding: 10px;}
.loginRgt {width: 100%;height:unset}
.loginLft {width: 100%;height:unset;}
.cateFilter li a {padding: 10px 0;font-size: 10px;}
.cateFilter ul li a{font-size:10px;}
.menu2{display:block;}
.cateFilterCont{display:none;}
.cateFilterCont2{width:50%;display:none;top:60px;}
.menu {display:none;}
.conrgt {width:100%;}
.toggleBtn2{display:none;}
.inputLabel {width:50%;margin: 3px 0px;}
.inputDate {width:50%;margin: 3px 0px;}
.selRslt ul {width:100%;}
.cateFilterCont3{display:none;}
.userLogformLft ul li .userLogCloud2 {width:100%;}
.userLogformLft ul li .userLogCloud4 {width:100%;}
.userLogformLft ul li .userLogCloud3 {width:100%;}
.userLogformLft ul li .userLogCloud44 {width:100%;}
.userLogformLft ul li .userLogCloud6{width:100%;}
.userLogCloud {width:100%;}

.toggleSwitch {padding: 3px;margin-left: 0px;margin-top:0px;}


.userLogformTop {width: 100%;}
/* .userLogformTop ul li {
  width: 50% !important;} */
.userLogformTop2 {width: 100%;}
.userLogformTop3 {width: 100%;}
.userLogBtnTop3 button{width:90px;font-size:11px;}

.userLogBtnTop {width: auto;margin:0 auto;display:table;float:none;}

.toogleSwitch {padding: 5px;}

.headBtomMenu li a {font-size: 12px;}
.userLogHead small{font-size:10px;}
.userLogHeadinnerRgt{display:none;}

.lightBox4 {width:500px;}

.lightBox5 {width:500px;}
.userLogHeadinner {width:100%;}
.graphPop6 {width: 420px!important;}

.ChartLft{width:100%;margin-bottom: 5px;}
.ChartRgt{width:100%;}
.DashBetSecLft,.DashBetSecRgt{width:100%;margin-bottom: 5px;}
.DashRgt { width: auto;float:none;}
.DashcontDateCont {width:100%;margin:0 auto;display:table;float:none;padding-top:5px;}
.DashChartCont {padding-top: 15px;}
.DashboardContinr h3 {width: 100%;}


.graphPop7 {width: 308px;}
/* .userLogBtnTop3 {padding:0;} */

#myfileExport {min-width: 100px;font-size:12px;padding: 8px 8px;}

.userLogBtnTop3{margin: 0 auto;display: table;float:none!important;}
.loginRgtInner img {  width: 119px;  border-radius: 1px;
  margin-top: 0px;  margin-left: 18px;}
.imgSec {  width: 168px;  height: 156px;  border-radius: 86px;
  padding: 10px;    margin: -2% auto;}
}
/*480*/
@media screen and (max-width: 639px) and (min-width: 480px)
{
.ContainerChart1{max-height:205px!important;}
.ContainerChart2{max-height:165px!important;}
/*Home*/
.menu2{display:block;padding: 5px 10px}
.cateFilterCont{width:50%;display:none;}
.cateFilterCont2{width:50%;display:none;top:60px;}
.menu {display:none;}
.conrgt {width:100%;}
.toggleBtn2{display:none;}
.inputLabel {width:50%;margin: 3px 0px;}
.inputDate {width:50%;margin: 3px 0px;}
.selRslt ul {width:100%;}
.lightBox3 {width: 400px;}
.lightBox2 {width: 250px;}
.cateFilterCont3{display:none;}


.loginRgt {width: 100%;height:unset}
.loginLft {width: 100%;height:unset;}
.loginContentFrm {width: 400px;}
.loginRgtInner {width: auto;margin: 2% auto;}
.loginRgtInner img {width: 119px;  border-radius: 1px;  margin-top: 0px;  margin-left: 18px;}
.loginRgtInner h2 {font-size: 20px;}
.loginRgtInner p {font-size: 14px;}
.loginRgtInner a {padding: 2px 20px;}
.loginLft{padding:0;}
.errorContent {width:100%;}
.errorContentInner {width: 100%;}

.userLogformLft ul li .userLogCloud2 {width:100%;}
.userLogformLft ul li .userLogCloud4 {width:100%;}
.userLogformLft ul li .userLogCloud3 {width:100%;}
.userLogformLft ul li .userLogCloud44 {width:100%;}

.userLogformLft ul li .userLogCloud6{width:100%;}
.userLogCloud {width:100%;}

.graphPop4 {width: 300px!important;}
.graphPop7 {width: 320px!important;left:0;top: 36px;}
.graphPop6 {width: 300px!important;}
#myInput {width: 150px;}
.searchInput {width: 150px;}

.togglemenu2 {width: 10%;}

.toggleSwitch {padding: 3px;margin-left: 0px;margin-top:0px;}

.lightBox4 {width:400px;}

.userLogformTop {width: 100%;}
.userLogformTop2 {width: 100%;}
.userLogformTop3 {width: 100%;}
.userLogBtnTop {width: auto;margin:0 auto;float:left;}
.toogleSwitch {padding: 5px;}
.userLogCloudtop input {padding: 5px 5px;}
.userLogCloudtop select {padding: 5px 5px;}
.userLogformTop ul li {width: 100% !important;}
.userLogformTop2 ul li {width: 100% !important;}
.userLogformTop3 ul li {width: 100% !important;}
.userLogBtnTop3 a{position:unset;}
.userLogBtnTop button {padding: 5px 10px;}
#myfileExport {padding: 5px 10px;}
.userLogHeadinnerRgt{display:none;}
.userLogHeadinner {width:100%;}

.lightBox5 {width:400px;}

.PreviewHeadinnr {width:100%;}

.DashBetSecLft,.DashBetSecRgt{width:100%;margin-bottom: 5px;}
.ChartLft{width:100%;margin-bottom:5px;}
.ChartRgt{width:100%;}
.DashBetSec {padding-top: 10px;}
.DashcontInner { width: auto;float: left; margin: 2px 2px;padding: 6px 12px;}
.DashcontDate ul li a {padding:6px 10px;}
.DashcontDateCont label {font-size: 12px;}
.DashChartCont {padding-top: 15px;}
.DashcontDateCont {width:100%;margin: 2px 0;}
.ChartLft,.ChartRgt{height:unset;}
.DashRgt { width: auto;float:none;}
.DashbetBtm ul li small {font-size: 8px;}
.DashboardContinr h3 {width: 100%;}

.userLog2 ul li label {font-size: 12px;}
.userLog2 ul li span{font-size: 12px;}

.LottoRst{width:100%;}
.userLogCloud5 input{width: 45px;}
.userLogBtnTop3 button {padding: 5px 10px;font-size: 12px;}
.userLogBtnTop button {padding: 5px 10px;font-size: 12px;}
.userLogBtnTop3{float:left;}
.imgSec {  width: 168px;  height: 156px;  border-radius: 86px;  padding: 10px;    margin: -2% auto;
}
}
/*320*/
@media screen and (max-width: 479px)
{
.ContainerChart1{max-height:350px!important;}
.ContainerChart2{max-height:350px!important;}
.LoginLOgo{width:99%;}
.menu2{display:block;padding:5px 8px;}
.cateFilter li a {padding: 5px 0;}
.cateFilter ul li a {padding: 0px 0 0;font-size:11px;}
.cateFilterCont{width:50%;display:none;}
.cateFilterCont2{width:80%;display:none;top:60px;}
.menu {display:none;}
.conrgt {width:100%;}
.toggleBtn2{display:none;}
.menu ul li a {font-size:12px;}
.dropdown-content {width:200px;}
.dropdown-content2 {min-width: 300px;}
.selRsltCont {width:145px;margin: 0 2px;}
.selRsltCont p {margin: 1px 0 0 0;}
.BtnCont {width: auto;float:none!important;margin: 0 auto;display: table;padding-top:5px;}
.selRsltCont input{width:100%;}	
.inputLabel {width:50%;margin: 3px 0px;}
.inputDate {width:50%;margin: 3px 0px;}
.selRslt ul {width:100%;}

.lightBox3 {width:250px;}
.lightBox2 {width:250px;}
.userLog h2 { font-size: 13px;}
.userLog span {font-size: 10px;}
.userlogInput input {margin: 9px 5px 0 0;}
.cateFilterCont3{display:none;}
.dropbtn .fa {color: #5f5f5f;font-size: 16px;}


.loginRgt {width: 100%;height:unset;padding: 10px 0;}
.loginLft {width: 100%;height:unset;}
.loginContentFrm {width:100%;}
.loginRgtInner {width: auto;margin: 2% auto;}

.loginRgtInner h2 {font-size: 20px;}
.loginRgtInner p {font-size: 14px;}
.loginRgtInner a {padding: 2px 20px;}
.loginLft{padding:0;}
.userLogContdrbbtn .button {height:40px;}
.loginContentFrm p {font-size: 13px;}
.errorContent {width:100%;}
.errorContentInner {width: 100%;}


.userLogformLft ul li .userLogCloud2 {width:100%;}
.userLogformLft ul li .userLogCloud4 {width:100%;}
.userLogformLft ul li .userLogCloud3 {width:100%;}
.userLogformLft ul li .userLogCloud44 {width:100%;}

.userLogformLft ul li .userLogCloud6{width:100%;}
.userLogCloud {width:100%;}
.userLogHead h2{width:100%;float:left;font-size:16px;}

.graphPop4 {width:240px!important;right: 50px;}
.graphPop7 {width:315px;right: -15px;top: 36px;}
.graphPop6 {width:200px!important;right: 50px;}


.userLogCloud input {padding: 5px 5px;}
.userLogformLft ul li .userLogCloud2 div input {padding:5px 5px;}
.userLogformLft ul li .userLogCloud4 div input {padding:5px 5px;}
.userLogformLft ul li .userLogCloud3 div input {padding:5px 5px;}
.userLogformLft ul li .userLogCloud44 div input {padding:5px 5px;}

.ButtonArea {float:none;margin: 0px auto;display: table;padding-top: 10px;}
.tableSearchreport {width: auto;margin: 0 auto;display: table;float:none;}
.logomenu2 a {width:180px;}
.toggleSwitch {padding: 3px;margin-left: 0px;margin-top:0px;}


.lightBox4 {width:290px;}
.PreviewHeadinnr {width:100%;}
.lightBox5 {width:290px;}
.lightBox7 {width:290px;}

.userLogformTop {width: 100%;}
.userLogformTop2{width: 100%;}
.userLogformTop3{width: 100%;}
.userLogBtnTop {width: auto;margin:0 auto;display:table;float:none;}
.toogleSwitch {padding: 5px;}
.headBtomMenu li a {font-size:10px;}
.userLogHead small{font-size:10px;}
.userLogCloudtop input {padding: 5px 5px;}
.userLogCloudtop select {padding: 5px 5px;}
.userLogformTop ul li {width: 100% !important;}
.userLogformTop2 ul li {width: 100% !important;}
.userLogformTop3 ul li {width: 100% !important;}
.userLogBtnTop3 a{position:unset;}
.userLogBtnTop button {padding: 5px 10px;
    min-width: 65px;
    font-size: 12px;}
#myfileExport {padding: 5px 10px;min-width: 85px;}

.userLogHeadinnerRgt{display:none;}
.userLogHeadinner {width:100%;}

/**/
.DashBetSecLft,.DashBetSecRgt{width:100%;margin-bottom: 5px;}
.ChartLft{width:100%;margin-bottom:5px;}
.ChartRgt{width:100%;}
.DashBetSec {padding-top: 10px;}
.DashcontInner { width: auto;float: left; margin: 2px 0;padding: 6px 12px;}
.DashcontDate ul li a {padding:6px 10px;}
.DashcontDateCont label {margin: 11px 5px 0px;font-size: 12px;}
.DashChartCont {padding-top: 15px;}
.ChartLft,.ChartRgt{height:unset;}

.DashbetBtm ul li small {font-size: 8px;}
.DashbetBtm ul li {width: 33.3333%;float: left;}
.lightBox6 {width:280px;}

.DashcontInner, .DashcontInner1 {width:100%;}
.userlogSelect1 {width:100%;}
.DashboardCont {padding: 10px;}
/* .DashbetBtm ul li img:hover{border:none;} */

.container .checkmark:after {
    top: 5px;left: 5px;width: 5px;height: 5px;}
.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
width: 15px;}

.radioBtn label{margin-top:0;}
.container {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom:0px;
cursor: pointer;
    font-size: 12px;
}
.userLogCloud div label{font-size:12px;}
.userLogformLft ul li .userLogCloud2 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud4 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud3 div label{font-size:12px;}
.userLogformLft ul li .userLogCloud44 div label{font-size:12px;}

.userLogCloud2 .logoDiv2 {width: 110px;}
.AdhaarDtls h4 {margin-top:0;width: 45%;}

.userLogCloud5 input {width: 40px;}
.userLogCloud5 span {width: 100%;}
.LottoRst2 {width: 100%;}
.LottoRst{width: 100%;}
.lightBox8 {width:280px;}
.userLog2 ul li label {font-size: 12px;}
.userLog2 ul li span{font-size: 12px;}


.userLogBtnTop3 button {min-width: 65px;padding: 5px 5px;}
.userLogformLft ul li .userLogCloud6 div input {width:90%;padding: 5px 5px;font-size:12px;}
.userLogBtnTop3{float:left;}

}

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}
/* by thamil */
.alignRight{} /* text-align: right !important; */
.disableCtrl:disabled{background: hsl(0, 0%, 95%)!important;border-color: hsl(0, 0%, 90%)!important;}

/* React Table Style */
.ReactTable .rt-thead .rt-th{font-size: 14px!important;font-family: Poppins-Medium!important;background: #495057!important;color: #fff !important;font-weight: bold!important;word-break: break-all!important;}
.ReactTable .rt-tfoot .rt-tr .rt-td{font-size: 13px!important;font-family: Poppins-Medium!important;background: #888888!important;color: #fff!important;font-weight: bold!important;word-break: break-all!important;}
.ReactTable .rt-tbody .rt-tr .rt-td {font-size: 12px!important;font-family: Poppins-Regular!important;word-break: break-all!important;}
/* .ReactTable .rt-tbody .rt-tr .rt-td:nth-child(-n + 1){background:#C8C8C8!important;} */
/* by thamil */
.ReactTable .rt-tfoot .rt-tr .rt-td{} /*text-align: right!important;*/
.ReactTable .-pagination{background:#E8E8E8!important;}
.ReactTable .-pagination .-btn{font-weight: bold!important;background: #495057!important;color: #fff!important;}
.hashbackColor{background:#C8C8C8!important;}
.ReactTable input, .ReactTable select{border: 1px solid #ccc!important}
.graphPop4Table{font-weight: bold!important;}
.graphPop4Table span{color: #228B22!important;}
.ReactTable .rt-thead .rt-td.-sort-desc, .ReactTable .rt-thead .rt-th.-sort-desc{
box-shadow: inset 0 -3px 0 0 rgb(255 255 255 / 60%) !important;
}
.ReactTable .rt-thead .rt-td.-sort-asc, .ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgb(255 255 255 / 60%) !important;
}
.chkBoxToggle{text-align: center;}
/* React Table Style */

.rptdatePicker{width: 100%;float: left;}

.eyeIcon{width:25px;height:22px;display:table;margin:0 auto;cursor:pointer;font-size:12px;color:#ffffff;padding:3px 0;background:url("../img/icons/open-eye.png") no-repeat 93% 1px;
-webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
   -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;float:right;position:relative;top:-30px;left:-10px;
    transition: all 0.3s linear;font-family: 'HelveticaNeueLTStd-LtCn';}
.eyeClose{background:url("../img/icons/closed-eye.png") no-repeat 98% 1px;}

/*
 *  scrollbar
 */
 .scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #f1f1f1;
	border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #f1f1f1;
	border-radius: 10px;
}

.scrollbar::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #ccc;
	/* background-image: #34c38f; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*bootstrap icons*/
.submenu{
  marginRight:"5px";
  fontSize:"18px";
  color: "#0A7CEE";
}
.radioBtnlg{width:100%;float:left; margin-left: 22%;margin-top: -5%;}

